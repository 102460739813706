
.cardy {
  height: 400px;
  padding: 2rem;
  overflow: hidden;
  overflow-y: unset;
  box-shadow: 1px 1px 5px .5px lightgray;
  background-color: white;

}


.cardy p {
  color:darkslateblue;
  text-align: left;

}

