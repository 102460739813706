* {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

.App {
  text-align:center;
  min-height: 100vh;
  margin: 0;
  overflow:hidden;
}

.placeholder {
  color: tomato;
  border: 3px solid
}

.oswald-font {
  font-family: 'Oswald', sans-serif;
}

.roboto-font {
  font-family: 'Roboto Slab', serif;
}

.noto-font {
  font-family: 'Noto Sans TC', sans-serif;
}

.noto-black-font {
  font-family: 'Noto Sans TC', sans-serif;
  font-weight: 900;
  
  
}

.staatliches-font {
  
  font-family: 'Staatliches', cursive;
}

.anton-font {
  font-family: 'Anton', sans-serif;
}

p, h1, h2, h5 {
  font-family: 'Noto Sans TC', sans-serif;
  
}