.detail {
  background-color: rgb(58, 58, 58);
  margin: auto;
}
.detail-header {
  font-size: 2rem;
  color: white;
}

.detail-text p {
  color: whitesmoke;
}


.detail-pic {
  max-width: 60%;
  border-radius: 5px;
  filter: sepia(30%);
  border: 1px solid white;
}




@media only screen and (max-width: 1400px) {
  .detail-pic {
    max-width: 80%;
  }
}

