.project-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  /* grid-template-rows: 200px 200px 200px 200px; */
  overflow:hidden;

}

@media screen and (max-width: 1200px) {
  .project-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media screen and (max-width: 1000) {
  .project-grid {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 800px) {
  .project-grid {
    grid-template-columns: 1fr;
    width: 85%;
    margin: auto;
    gap: 2rem;

  }
}
