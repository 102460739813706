.project-card {
  margin: auto;
  border: 1px solid white;
  transition: 400ms all;
  filter:brightness(50%);
  max-height: 200px;
}

.project-card-img {
  width: 100%;
  filter: sepia(30%);
  /* object-fit: contain; */
  overflow:hidden;

}

.project-card:hover {
  cursor: pointer;
  filter:brightness(100%);
}
