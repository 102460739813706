.services-text {
  padding: 40px;
  background-color: rgb(241, 241, 241);

}

.services-box {
  min-height: 50vh;
}



.client-box {
  min-height: 60vh;
  background-color: rgb(241, 241, 241);
  background-color: rgb(241, 241, 241);



}

.projects-box {
  min-height: 90vh;

}

.about-pic {
  /* object-fit: cover; */
  width: 100vw;

}

@media only screen and (max-width: 900px) {
  .services-box p {
    text-align:center !important;
  
  }
}
