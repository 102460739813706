#project-modal-container {
  backdrop-filter: blur(5px);
  position: fixed;
  z-index: 777;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display:grid;
  justify-items: center;
}
.project-modal {
  cursor: pointer;
  box-shadow: 1px 1px 0.5px grey;
  width: 45vw;
  margin: auto;

  position: relative;
}

.project-modal img {
  /* object-fit: contain; */
  width: 100%;
  margin: auto;
}

.project-modal-text {
  color: white;
  position: relative;
  width: fit-content;
  padding: 2rem;
  position: absolute;
  bottom: 40%;
  margin-top: 60%;
  opacity: 0;
  transition: 300ms all;
  z-index: 888;
}

.project-modal:hover .project-modal-text {
  opacity: 1;
}

.project-modal:hover>img {
  filter:brightness(50%);
}

@media screen and (max-width: 800px){
  .project-modal {
    width: 85vw;
  }

  .project-modal-text{
    bottom:0;
    opacity: 1;
  }
  .project-modal img {
    filter:brightness(70%);

  }
}
