.about-pic {
  max-width: 100vw;
  filter: brightness(60%);
}

.about-section {
  position: relative;
  max-height: 50vh;
  overflow: hidden;
}

.about-header-text,
.contact-header-text {
  border: 5px solid white;
  position: absolute;
  width: fit-content;
  padding: 10px;
  top: 40%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  color: white;
}
.about-text {
  padding-top: 3rem;
  padding-right: 15%;
  padding-bottom: 8rem;
  min-height: 80vh;
  background-color: rgb(241, 241, 241);
  text-align: left;
}

.about-container {
  display: grid;
  background-color: rgb(241, 241, 241);
  grid-template-columns: 500px 500px;
  column-gap: 0px;
  justify-content: center;
  
}

.joe-img-container{
  margin-top: 50px;
}

.joe-img {
max-width: 400px;

}

#bags-about-logo {
  background-color: rgb(241, 241, 241);

}
#bags-about-logo img {
  margin-top: 3rem;
  height: 35vh;
  width: auto;
}

@media only screen and (max-width: 900px) {
.joe-img {
  padding:  15px;
}

  .about-container{
    grid-template-columns: 500px;
    
  }

  .about-text {
    padding-left: 15%;

  }
}
@media only screen and (max-width: 600px) {
  .about-header-text {
    top: 25%;
  }
  .contact-header-text {
    top: 10%;
    max-width: 70%;
  }

}

