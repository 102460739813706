.banner-homepage {
  position: relative;
  overflow: hidden;
}
.hero-banner {
 width: 100vw;
  filter: brightness(70%);
}

.bags-logo {
  max-width: 40%;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 20%;
}

.intro-section {
  min-height: 80vh;
  background-color: rgb(241, 241, 241);
}

.details-section {
  max-width: 100vw;
}

.uncle {
  position:relative;
}

.box-text {
  /* background-color: white; */
  border: 2px solid whitesmoke;
  color: white;
  opacity: 0.85;
  /* border-radius: 5px; */
  margin: auto;
  margin-top: 150px;
  /* width: fit-content; */
  width: 65%;
  position: absolute;
  z-index: 22;
  top: 0;
  left:0;
  right:0;
  margin-left: auto;
  margin-right: auto;
  letter-spacing: 2px;

}

.end-section {
  background-color: whitesmoke;
  padding: 40px;
  min-height: 60vh;
  background-image: url("/photos/sf-hill-shot-lofi.jpg");
  filter:brightness(50%);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
