.footer-box {
background-color: rgb(58, 58, 58);
}

.footer-box a {
  color: cyan;
  transition: 300ms all;
}

.footer-box a:hover {
  font-style: none;
  color: magenta;
  text-decoration: none;

}